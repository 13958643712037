.main_header {
  background-color: var(--color-white);
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
}
.header_inner {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}
.brand {
}
.hamburger_menu {
  cursor: pointer;
}
