.sidebar {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  visibility: hidden;
}

.sidebar_content {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  min-width: 250px;
  z-index: 1;
  overflow: auto;
  background-color: white;
  box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.1);
  transform: translateX(110%);
  transition: all 0.3s ease-out;
}

.open {
  visibility: visible;
}

.open .sidebar_content {
  transform: translateX(0%);
}
.open .sidebar_overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.09);
  backdrop-filter: blur(10px);
}

.close_icon {
  margin-top: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.sidebar .sidebar_links {
  margin: 0;
  padding-left: 0;
}

.sidebar .sidebar_links li {
  padding: 15px 25px;
  font-size: 20px;
  cursor: pointer;
}

.sidebar .sidebar_links li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.sidebar .sidebar_links li.active {
  background-color: var(--color-primary);
}
