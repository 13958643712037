.footer_content {
  z-index: 1;
  overflow: auto;
  background-color: white;
  box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}

.footer .footer_links {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 12px;
  width: 80%;
  margin: auto;
  padding: 30px 0;
}

.footer .footer_links li {
  padding: 9px 16px;
  font-size: 16px;
  cursor: pointer;
}

.footer .footer_links li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.footer .footer_links li.active {
  font-weight: 600;
  /* background-color: var(--color-primary); */
}

/* Media queries for screens smaller than 991px */
@media screen and (max-width: 991px) {
  .footer .footer_links {
    grid-template-columns: repeat(2, auto);
  }
}

/* Media queries for screens smaller than 767px */
@media screen and (max-width: 767px) {
  .footer .footer_links {
    grid-template-columns: repeat(1, auto);
    gap: 5px;
  }
}
