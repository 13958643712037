.back_to_top_button {
  position: fixed;
  bottom: 30px;
  right: 20px;
  padding: 10px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 60px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.back_to_top_button.visible {
  opacity: 0.6;
}

.back_to_top_button.visible:hover {
  opacity: 1;
}
