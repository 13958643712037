*{
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  @font-face {
    font-family: american_typewriter;
    src: url(../fonts/AMERICAN_TYPEWRITER_REGULAR.TTF);
  }
}

code {
  @font-face {
    font-family: american_typewriter;
    src: url(../fonts/AMERICAN_TYPEWRITER_REGULAR.TTF);
  }
}
